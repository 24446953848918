import React from 'react';
import { FULL_ACCESS } from './OperatorStatuses';

const CompanyDashboard = React.lazy(() => import('../../pages/CompanyDashboard'));
const SeafarerDashboard = React.lazy(() => import('../../pages/SeafarerDashboard'));
const InterviewDashboard = React.lazy(() =>
  import('../../pages/InterviewDashboard')
);
const HireDashboard = React.lazy(() => import('../../pages/HireDashboard'));
const BroadcastMessage = React.lazy(() => import('../../pages/BroadcastMessage'));
const Settings = React.lazy(() => import('../../pages/Settings'));
const NotFound = React.lazy(() => import('../../pages/NotFound'));
const SeafarerCV = React.lazy(() => import('../../pages/SeafarerCV'));
const SeafarerReducedCV = React.lazy(() => import('../../pages/SeafarerReducedCV'));
const CompanyDetails = React.lazy(() => import('../../pages/CompanyDetails'));
const NewCompany = React.lazy(() =>
  import('../../pages/CompanyDetails/NewCompanyForm')
);
const SeafarerDetails = React.lazy(() => import('../../pages/SeafarerDetails'));
const NewSeafarer = React.lazy(() =>
  import('../../pages/SeafarerDetails/NewSeafarerForm')
);
const SeafarerVerification = React.lazy(() =>
  import('../../pages/SeafarerVerification')
);
const Search = React.lazy(() => import('../../pages/Search'));
const SavedCandidates = React.lazy(() => import('../../pages/SavedCandidates'));
// const ExcludedCandidates = React.lazy(() =>
//   import('../../pages/Excluded Candidates')
// );
const SavedSearches = React.lazy(() => import('../../pages/SavedSearches'));
const VesselData = React.lazy(() => import('../../pages/VesselData'));

export default [
  {
    key: 'CompanyDashboard',
    component: CompanyDashboard,
    path: '/companies',
    label: 'Companies',
    exact: true,
    icon: 'uil-ship',
    url: '/companies',
    isMenuLink: true,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'CompanyDetails',
    component: CompanyDetails,
    path: '/companies/:id',
    label: 'View Company',
    exact: true,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'NewCompany',
    component: NewCompany,
    path: '/company/new',
    label: 'New Company',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'SeafarerDashboard',
    component: SeafarerDashboard,
    path: '/seafarers',
    label: 'Seafarers',
    exact: true,
    icon: 'uil-user-square',
    isMenuLink: true,
    url: '/seafarers',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'SeafarerDetails',
    component: SeafarerDetails,
    path: '/seafarers/:id',
    label: 'View Seafarer',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'SeafarerVerification',
    component: SeafarerVerification,
    path: '/seafarers/:id/verification',
    label: 'Seafarer Verification',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'SeafarerCV',
    component: SeafarerCV,
    path: '/seafarers/:id/printable-version',
    label: 'Seafarer CV',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'SeafarerReducedCV',
    component: SeafarerReducedCV,
    path: '/seafarers/:id/reduced-cv',
    label: 'Seafarer Short CV',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'NewSeafarer',
    component: NewSeafarer,
    path: '/seafarer/new',
    label: 'New Seafarer',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'contact-requests',
    component: InterviewDashboard,
    path: '/interviews',
    label: 'Contact Requests',
    exact: true,
    icon: 'uil-comments-alt',
    isMenuLink: true,
    url: '/interviews',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'HireDashboard',
    component: HireDashboard,
    path: '/hires',
    label: 'Hires',
    exact: true,
    icon: 'uil-users-alt',
    isMenuLink: true,
    url: '/hires',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'BroadcastMessage',
    component: BroadcastMessage,
    path: '/messages',
    label: 'Broadcast Messages',
    exact: true,
    icon: 'uil-comment-alt-notes',
    isMenuLink: true,
    isTitle: false,
    url: '/messages',
    permission: [...FULL_ACCESS]
  },
  {
    key: 'VesselData',
    component: VesselData,
    path: '/vesselData',
    label: 'Vessel Data',
    exact: true,
    icon: 'uil-ship',
    isMenuLink: true,
    url: '/vesselData',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'candidate-searches',
    label: 'Candidate Search',
    isTitle: true,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'search',
    component: Search,
    path: '/search',
    label: 'Search',
    exact: true,
    icon: 'uil-search-alt',
    isMenuLink: true,
    url: '/search',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'search-action',
    component: Search,
    path: '/search/:id/:mode',
    label: 'ViewEditSearch',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'saved-searches',
    component: SavedSearches,
    path: '/saved-searches',
    label: 'Saved Searches',
    exact: true,
    icon: 'uil-file-search-alt',
    isMenuLink: true,
    url: '/saved-searches',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'saved-candidates',
    component: SavedCandidates,
    path: '/saved-candidates',
    label: 'Saved Candidates',
    exact: true,
    icon: 'uil-user-plus',
    isMenuLink: true,
    url: '/saved-candidates',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  // {
  //   key: 'excluded-candidates',
  //   component: ExcludedCandidates,
  //   path: '/excluded-candidates',
  //   label: 'Excluded Candidates',
  //   exact: true,
  //   icon: 'uil-user-plus',
  //   isMenuLink: true,
  //   url: '/excluded-candidates',
  //   isTitle: false,
  //   permission: [...FULL_ACCESS]
  // },
  {
    key: 'Settings',
    component: Settings,
    path: '/settings',
    label: 'Settings',
    exact: true,
    isMenuLink: false,
    isMobileLink: true,
    icon: 'uil-cog',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'NotFound',
    component: NotFound,
    label: 'Not Found',
    isMenuLink: false,
    isTitle: false,
    permission: [...FULL_ACCESS]
  }
];
